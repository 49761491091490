<template>
    <admin-table title="临时排班" @search="handleSearch" :table="table" :loading="loading">
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
                <el-button size="small" type="warning" icon="el-icon-edit" @click="handleEdit(scope.row)"/>
                <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                            width="180">
                    <p>确定删除[{{scope.row.doctor}}]的记录吗？</p>
                    <div>
                        <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                        </el-button>
                        <el-button size="mini" type="default"
                                   @click="$refs[`popover-${scope.$index}`].doClose()">取消
                        </el-button>
                    </div>
                    <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                </el-popover>
            </template>
        </el-table-column>
    </admin-table>
</template>

<script>
    export default {
        data() {
            function areaFormatter(t) {
                let result = ''
                t.area.forEach(e => {
                    result += `${e.name} `
                })
                return result
            }

            function datesFormatter(t) {
                return t.dates.join(',')
            }

            function timesFormatter(t) {
                let result = ''
                t.times.forEach(e => {
                    result += `${e.time}(${e.count}) `
                })
                return result
            }

            return {
                loading: false,
                table: {
                    buttons: [
                        {
                            label: '新建临时排班',
                            type: 'primary',
                            icon: 'el-icon-plus',
                            path: '/main/worksheet/overtime/form',
                        }
                    ],
                    search: [
                        {type: 'input', key: 'keywords', placeholder: '请输入关键词'}
                    ],
                    columns: [
                        {title: '医生', field: 'doctor', width: ''},
                        {title: '手术区', field: 'area', width: '', formatter: areaFormatter},
                        {title: '日期', field: 'dates', width: '', formatter: datesFormatter},
                        {title: '时间', field: 'times', width: '', formatter: timesFormatter},
                        {title: '备注', field: 'desc', width: ''},
                    ],
                    data: [],
                    total: 0,
                },
            };
        },
        methods: {
            handleEdit(row) {
                let path = `/main/worksheet/overtime/form?id=${row.id}`
                this.$store.commit('setRouterHistory', {label: `临时排班 - ${row.doctor}`, path: path})
                this.$router.push({
                    path: path
                })
            },
            async handleDel(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/overtime/${row.id}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            handleSearch(e) {
                this.getData(e)
            },
            async getData(e) {
                const resp = await this.$http.get(`/surgery/overtime/`, {params: e})
                this.table.data = resp.data.data.data
                this.table.total = resp.data.data.total
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

